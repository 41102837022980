<template>
  <div>
    <Panel :module="panel.module" :title="panel.title" :showFilter="panel.showFilter" :showSearch="panel.showSearch"
      :showButtons="panel.showButtons">
      <div slot="content-main">
        <div>
          <br />
          <b-tabs v-model="tabIndex">
            <b-tab title="Tag">
              <Tag v-if="tabIndex == 0" />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>
import Tag from './Tag.vue'

import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";

import { mapMutations } from "vuex";

export default {
  name: "ConfigPlannerView",
  components: {
    Panel,
    Tag
  },
  data() {
    return {
      panel: {
        module: "Estoque",
        title: "Configuração",
        showFilter: false,
        showSearch: false,
        showButtons: false,
      },
      tabIndex: 0,
    };
  },
  mounted() {
    this.removeLoading(["panel"]);
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
  },
};
</script>
